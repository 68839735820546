import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

/* 全局css */
import './assets/css/overall.scss'
import './assets/css/index.scss'
import './assets/css/defaultright.scss'
import './assets/css/left.scss'
import './assets/css/bottom.scss'
import './assets/css/headerImg.scss'
import './assets/css/rotation.scss'
import './assets/css/otherImg.scss'
import './assets/css/friendright.scss'
import './assets/css/lifeContent.scss'
import './assets/css/photoContent.scss'
import './assets/css/aboutContent.scss'
import './assets/css/messageContent.scss'
import './assets/css/articleContent.scss'
import './assets/css/blogpostContent.scss'
import animated from "animate.css"
Vue.use(animated)

/* 引入element-ui库*/
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

/* 引入axios接口 */
import External from './network/External'
import home from './network/home'
import message from './network/message'
Vue.prototype.$External = External
Vue.prototype.$home = home
Vue.prototype.$blogmessage = message

/* 时间轴 */
import hzqingVueTimeline from 'hzqing-vue-timeline'
Vue.use(hzqingVueTimeline)

/* 引入markdown编辑器 */
import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
/* 引入语言包 */
import 'prismjs/components/prism-java'
/* 引入mavon-editor */
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

Vue.use(mavonEditor);
VMdPreview.use(vuepressTheme,{
  extend(md){
  }
})

Vue.use(VMdPreview);

/* 引入阿里图标库 */
import './assets/icon/iconfont.js'

/* 引入时间 */
import moment from 'moment'
Vue.prototype.$moment = moment

/* 全局修改弹出框停留时长，注意，一定要在vue.use后面写*/
Vue.prototype.$message = function (msg) {
  ElementUI.Message(msg)
}
Vue.prototype.$message.success = function (msg) {
  return ElementUI.Message.success({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$message.error = function (msg) {
  return ElementUI.Message.error({
    message: msg,
    duration: 1500
  })
}
Vue.prototype.$message.warning = function (msg) {
  return ElementUI.Message.warning({
    message: msg,
    duration: 1000
  })
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
